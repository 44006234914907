import React from 'react'
import { useForm } from 'react-hook-form';

function Search () {

  const { register, handleSubmit } = useForm();
  const onSubmit = data => {
    if (typeof window !== 'undefined') {
      window.history.replaceState(null, null, `/#!/search?query=${data.query}&category=${data.category}`);
      window.location.reload();
    } else {
      console.log('This feature does not work on Develop', data)
    }
  }
  return (
    <form className='md:max-w-screen-md mx-auto flex flex-row flex-wrap sm:flex-no-wrap items-stretch md:px-10' onSubmit={handleSubmit(onSubmit)}>
      <div className='flex-grow md:w-auto mb-2 md:mb-0'>
        <label className='sr-only' htmlFor='query'>Suche</label>
        <input name='query' id='query' type='text' placeholder='Suche' className='outline-none border border-gray-400 rounded-l-md h-12 p-2 w-1/2 sm:w-2/3 px-3' ref={register} />
        <label className='sr-only' htmlFor='category'>Kategorie</label>
        <select name='category' id='category' className='outline-none border border-gray-400 h-12 p-2 w-1/2 sm:w-1/3 rounded-r-md' onChange={handleSubmit(onSubmit)} ref={register}>
          <option value=''>Alle Kategorien</option>
          <option value='1699'>Additive Fertigung</option>
          <option value='173'>Bohrwerke</option>
          <option value='1745'>Fahrzeuge</option>
          <option value='1748'>Bauindustrie</option>
          <option value='226'>Erodiermaschinen</option>
          <option value='1754'>Elektronikindustrie</option>
          <option value='210'>Blechbearbeitungsmaschinen</option>
          <option value='20'>Umformmaschinen</option>
          <option value='130'>Schmiede und Gießerei</option>
          <option value='240'>Verzahnungsmaschinen</option>
          <option value='80'>Werkstatteinrichtung</option>
          <option value='280'>Messen und Prüfen</option>
          <option value='1782'>Laboranlagen</option>
          <option value='270'>Schrottverkauf</option>
          <option value='160'>Fräsmaschinen</option>
          <option value='1751'>Büroeinrichtung</option>
          <option value='260'>Kunststoffbearbeitung</option>
          <option value='233'>Pressen</option>
          <option value='1780'>Druck- und Verpackungsmaschinen</option>
          <option value='300'>Walzwerkanlagen / Profilieranlagen / Längsteilanlagen</option>
          <option value='1'>Drehmaschinen</option>
          <option value='40'>Holzbearbeitung</option>
        </select>
      </div>
      <button className='flex-grow md:ml-6 button bg-brand-orange text-white h-12' type='submit'>Suche</button>
    </form>
  )
}

export default Search
