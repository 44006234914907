import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from '@prismicio/gatsby-source-prismic-graphql'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import get from 'lodash/get';

const languageQuery = graphql`
  {
    grobritannien: file(relativePath: { eq: "flags/unitedkingdom.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    dede: file(relativePath: { eq: "flags/germany.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    vereinigtestaaten: file(relativePath: { eq: "flags/unitedstates.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    australien: file(relativePath: { eq: "flags/australia.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    italien: file(relativePath: { eq: "flags/italy.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    kanada: file(relativePath: { eq: "flags/canada.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    frankreich: file(relativePath: { eq: "flags/france.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    deutschland: file(relativePath: { eq: "flags/germany.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    sdafrika: file(relativePath: { eq: "flags/southafrica.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    china: file(relativePath: { eq: "flags/china.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    indien: file(relativePath: { eq: "flags/india.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    spanien: file(relativePath: { eq: "flags/spain.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    russland: file(relativePath: { eq: "flags/russia.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const Flag = props => {
  var language = props.language
  var hideLabel = props.hideLabel
  return (
    <StaticQuery
      query={`${languageQuery}`}
      render={withPreview(data => {
        var languageStrip = language.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')
        if (get(data[languageStrip], 'childImageSharp.fixed')) {
          var languageImage = data[languageStrip].childImageSharp.fixed
        }
        return (
          <>
           {languageImage ? (
              <span className='flex items-center'>
                <Img fixed={languageImage} className='mt-px' alt={`${language} logo`} /><span className={`ml-2 ${hideLabel && ('sr-only')}`}> {language}</span>
              </span>
            ):(
              <><span>No flag found for {language}</span></>
            )}
          </>
        )
      }, languageQuery)}
    />
  )
}
Flag.propTypes = {
  language: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool
}

export default Flag
