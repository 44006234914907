import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import get from 'lodash/get';

const socialQuery = graphql`
  {
    facebook: file(relativePath: { eq: "facebook.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    twitter: file(relativePath: { eq: "twitter.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    instagram: file(relativePath: { eq: "instagram.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    youtube: file(relativePath: { eq: "youtube.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    linkedin: file(relativePath: { eq: "linkedin.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const Social = props => {
  if (!props.socialData) return null
  const socialData = props.socialData
  return (
    <StaticQuery
      query={`${socialQuery}`}
      render={data => (
        socialData.body.map((social, index) => {
          var socialImage = data[social.primary.social_network.toLowerCase()].childImageSharp.fixed
          return (
            <a key={`social-${index}`} href={get(social.primary, 'social_network_url.url')} target='_blank' rel='noopener noreferrer' className='social-icon inline-block'>
              <Img fixed={socialImage} alt={get(social.primary ,'social_network')} className={`social-${get(social.primary ,'social_network')} w-5 mx-2 transform hover:scale-105`} loading='lazy' />
            </a>
          )
        })
      )}
    />
  )
}

export default Social
