import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from '@prismicio/gatsby-source-prismic-graphql'
import { linkResolver } from '../utils/linkResolver'
import get from 'lodash/get'
import PropTypes from 'prop-types'

const subNavigationQuery = graphql`
  {
    prismic {
      allNavigations(lang: "de-de") {
        edges {
          node {
            body1 {
              ... on PRISMIC_NavigationBody1Nav_item {
                type
                label
                primary {
                  label
                  label_description
                  link {
                    ... on PRISMIC_Blogpost{
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SubNavigation = ({bidJSPath}) => {
  return (
    <StaticQuery
      query={`${subNavigationQuery}`}
      render={withPreview(data => {

        const navigationData = data.prismic.allNavigations.edges[0].node
        return (
          <nav className='hidden md:block max-w-screen-xl mx-auto p-4 lg:px-8 w-full'>
            <ul className='flex items-stretch'>
              {navigationData.body1.map((subNav, index) => {
                var link = get(subNav.primary.link, 'url',(linkResolver(get(subNav.primary.link, '_meta',[]),[])))
                var linkSplit = link.split('/');
                var bidJSNav = linkSplit[linkSplit.length - 1];
                return (
                  <React.Fragment key={`subNav-${index}`}>
                    <li className='text-center border border-gray-400 flex-1 bg-white flex flex-col -ml-px'>
                      <a href={link} className={`block sm:py-1 sm:px-2 lg:py-4 xl:py-6 xl:px-3 hover:bg-gray-200 flex-1 ${bidJSPath === bidJSNav && ('border-b-4 border-brand-blue')}`}>
                        <div className='text-sm lg:text-base text-brand-blue uppercase font-semibold'>{subNav.primary.label}</div>
                        <div className='hidden lg:block text-gray-700 text-sm'>{subNav.primary.label_description}</div>
                      </a>
                    </li>
                  </React.Fragment>
                )
              })}
            </ul>
          </nav>
        )
      }, subNavigationQuery)}
    />
  )
}

SubNavigation.propTypes = {
  bidJSPath: PropTypes.string
}

export default SubNavigation
