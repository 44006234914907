import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'


const seoQuery = graphql`
  query {
    site {
      siteMetadata {
        siteAuthor: author
        siteDescription: description
        siteTitle: title
        siteTwitterImage: twitterImage
        siteOGImage: ogImage
        siteUrl: siteUrl
      }
    }
  }
`

const SEO = ({ author, description, image, lang, meta, title }) => (
  <StaticQuery
    query={`${seoQuery}`}
    render={({
      site: {
        siteMetadata: {
          siteAuthor,
          siteDescription,
          siteTitle,
          siteTwitterImage,
          siteOGImage,
          siteUrl,
        },
      },
    }) => {
      const seo = {
        author: author || siteAuthor,
        title: title || siteTitle,
        description: description || siteDescription,
        imageTwitter: image || siteUrl + siteTwitterImage,
        imageOG: image || siteUrl + siteOGImage,
      }
      return (
        <>
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={seo.title}
            titleTemplate={`%s`}
            meta={[
              {
                name: `description`,
                content: seo.description,
              },
              {
                property: `og:title`,
                content: seo.title,
              },
              {
                property: `og:description`,
                content: seo.description,
              },
              {
                property: `og:image`,
                content: seo.imageOG + '?&fit=crop&w=1200&h=630&crop=edges',
              },
              {
                property: `og:image:width`,
                content: `1200`,
              },
              {
                property: `og:image:height`,
                content: `630`,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: seo.author,
              },
              {
                name: `twitter:title`,
                content: seo.title,
              },
              {
                name: `twitter:image`,
                content: seo.imageTwitter + '?&fit=crop&w=150&h=150&crop=edges',
              },
              {
                name: `twitter:description`,
                content: seo.description,
              },
            ].concat(meta)}
          />
        </>
      )
    }}
  />
)

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.string,
}

export default SEO
