import React, { PureComponent } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from '@prismicio/gatsby-source-prismic-graphql'
import Img from 'gatsby-image'

const logoQuery = graphql`
  {
    logo: file(relativePath: { eq: "Apex-Auctions_Logo.png" }) {
      childImageSharp {
        fixed(width: 277) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`;

class Logo extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={`${logoQuery}`}
        render={withPreview(data => {
          return (
            <a href='/' className='block w-16 overflow-hidden sm:w-auto sm:overflow-auto'><Img fixed={data.logo.childImageSharp.fixed} alt='Apex logo' /><span className='sr-only'>Apex</span></a>
          )
        }, logoQuery)}
      />
    )
  }
}

export default Logo
