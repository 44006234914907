import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from '@prismicio/gatsby-source-prismic-graphql'
import { linkResolver } from '../utils/linkResolver'
import Social from './social'
import get from 'lodash/get'

let newDate = new Date()
let year = newDate.getFullYear();

const footerQuery = graphql`
  query {
    prismic {
      allNavigations(lang: "de-de") {
        edges {
          node {
            _meta {
              uid
              tags
            }
            company_display_name
            company_address
            company_phone
            body {
              ... on PRISMIC_NavigationBodyFooter_link_list {
                fields {
                  link_label
                  link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
                primary {
                  title
                }
              }
            }
          }
        }
      }
      allSocials(lang: "de-de") {
        edges {
          node {
            body {
              ... on PRISMIC_SocialBodySocial_network {
                label
                primary {
                  social_network
                  social_network_url {
                    ... on PRISMIC__ExternalLink {
                      target
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;


export const Footer = () => (
  <StaticQuery
    query={`${footerQuery}`}
    render={withPreview(data => {

      const navigationData = data.prismic.allNavigations.edges[0].node
      let socialData = ''
      if (data.prismic.allSocials.edges[0]) {
        socialData = data.prismic.allSocials.edges[0].node
      }
      return (
        <footer role='contentinfo' className='bg-brand-blue mt-auto'>
          <div className='max-w-screen-xl mx-auto py-8 px-4 mt-8'>
            <div className='grid grid-cols-4 col-gap-8'>
              {navigationData.body.map((footerList, index) => {
                return (
                  <div key={`footerList-${index}`} className='col-span-2 md:col-span-1'>
                    {footerList.primary.title && <h4 className='text-base font-bold leading-none pb-2 uppercase text-white'>{footerList.primary.title}</h4>}
                    <ul>
                      {footerList.fields.map((footerLink, index) => {
                        var link = get(footerLink.link, 'url',(linkResolver(get(footerLink.link, '_meta',[]),[])))
                        return (
                          <li className='flex' key={`footer-${index}`}>
                            <div className='py-2 text-orange-600 mr-3 font-black'><i className='fa fa-chevron-right' aria-hidden='true'></i></div>
                            <a href={link} className='py-2 md:pb-0 block font-medium text-white hover:text-brand-turquoise transform transition duration-150 ease-in-out'>{get(footerLink, 'link_label')}</a>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='mt-2 bg-brand-blue-dark'>
            <div className='max-w-screen-xl px-4 mx-auto text-base font-light text-white md:flex flex-row justify-between'>
              <div className='py-3 text-center md:text-left'>Copyright &copy; {year} - {get(navigationData, 'company_display_name')}</div>
              <div className='py-3 flex justify-center'><Social socialData={socialData} /></div>
            </div>
          </div>
        </footer>
      )
    }, footerQuery)}
  />
);

export default Footer
