import React, { PureComponent } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from '@prismicio/gatsby-source-prismic-graphql'
import get from 'lodash/get'
import { linkResolver } from '../utils/linkResolver'
import jwt_decode from 'jwt-decode'
import Flag from './flag'
import Img from 'gatsby-image'
import Logo from './logo'
import Search from './search'
import SubNavigation from './subNavigation'

const headerQuery = graphql`
  {
    user: file(relativePath: { eq: "user.png" }) {
      childImageSharp {
        fixed(height: 20) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    prismic {
      allNavigations(lang: "de-de") {
        edges {
          node {
            _meta {
              lang
            }
            company_address
            company_display_name
            company_phone
            nav {
              ... on PRISMIC_NavigationNavNav_item {
                primary {
                  label
                  link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
                fields {
                  sub_nav_link_label
                  sub_nav_link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Header = ({bidJSPath, loggedIn}) => {
  return (
    <>
      <header role='banner' className='bg-white'>
        <nav className='max-w-screen-xl mx-auto px-4 flex justify-between'>
          <Logo />
          <Navigation loggedIn={loggedIn} bidJSPath={bidJSPath} />
        </nav>
      </header>
      <div className='max-w-screen-xl mx-auto px-4 pt-5'>
        <Search />
      </div>
      <SubNavigation bidJSPath={bidJSPath} />
    </>
  )
}

class Dropdown extends PureComponent {
  constructor() {
    super()
    this.state = {
      subNavExpanded: false,
      isLoggedIn: false,
      token: null,
    }
    this.toggleSubNav = this.toggleSubNav.bind(this);
  }
  container = React.createRef();
  toggleSubNav(id) {
    if (id === this.state.subNavExpandedId) {
      this.setState({
        subNavExpandedId:false
      })
    } else {
      this.setState({
        subNavExpandedId:id
      })
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({
     isLoggedIn: this.props.isLoggedIn,
     token: localStorage.getItem('bidJSToken')
    })
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.container.current && !this.container.current.contains(event.target)) {
      this.setState({
        subNavExpandedId:false
      });
    }
  };

  render() {
    var data = this.props.data
    var nav = this.props.nav
    var isLoggedIn = this.state.isLoggedIn
    var decoded = false
    var username = ''
    var admin = false
    var vendor = false
    if (this.state.token !== null) {
      decoded = jwt_decode(this.state.token)
      username = decoded.username
      admin = decoded.adminLoginPermitted
      vendor = decoded.publicVendorAdmin
    }
    const headerData = data.prismic.allNavigations.edges[0].node
    return (
      <div ref={this.container}>
        <button className='flex flex-row leading-none whitespace-no-wrap py-2 px-3 text-gray-600 hover:text-gray-900 focus:text-gray-900 focus:outline-none rounded-full border border-gray-300' type='button' aria-haspopup='true' aria-expanded={this.state.subNavExpanded} onClick={() => this.toggleSubNav(`${nav.primary.label}`)} onKeyDown={() => this.toggleSubNav(`${nav.primary.label}`)}>
          {nav.primary.label.toLowerCase() === 'land' ? (
            <>
              <Flag language={headerData._meta.lang} hideLabel={true} />
              <span className='sr-only'>{nav.primary.label}</span>
            </>
          ):(
            <>
              {nav.primary.label.toLowerCase() === 'guten tag' ? (
                <div className='flex items-center'>
                  <Img fixed={data.user.childImageSharp.fixed} className='mt-px mr-1' alt='user icon' role='presentation' />
                  {isLoggedIn ? (
                    <span>Welcome {username}</span>
                  ):(
                    <span>{nav.primary.label}</span>
                  )}
                </div>
              ):(
                <div className='flex items-center'>{nav.primary.label}</div>
              )}
            </>
          )}
          <svg xmlns='http://www.w3.org/2000/svg' className='w-5' height='1em' width='1em'>
            <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' fill='currentColor'/>
            <path d='M0 0h24v24H0z' fill='none'/>
          </svg>
        </button>
        <ul className={`py-1 absolute z-30 right-0 bg-white shadow-md border rounded-sm ${this.state.subNavExpandedId === nav.primary.label ? 'block': 'hidden'}`}>
          {nav.primary.label.toLowerCase() === 'guten tag' && (
            <>
              <li key={`login${isLoggedIn}`} className={`${!isLoggedIn ? ('block'):('hidden')}`}><a className='block py-1 px-6 whitespace-no-wrap text-sm hover:bg-gray-200' href='../#!/login?redirect=%2F' onClick={() => this.toggleSubNav(`${nav.primary.label}`)}>Login</a></li>
              <li key={`createAccount${isLoggedIn}`} className={`${!isLoggedIn ? ('block'):('hidden')}`}><a className='block py-1 px-6 whitespace-no-wrap text-sm hover:bg-gray-200' href='../#!/account' onClick={() => this.toggleSubNav(`${nav.primary.label}`)}>Konto erstellen</a></li>
              <li key={`settings${isLoggedIn}`} className={`${isLoggedIn ? ('block'):('hidden')}`}><a href='../#!/mySettings' className='block py-1 px-6 whitespace-no-wrap text-sm hover:bg-gray-200' onClick={() => this.toggleSubNav(`${nav.primary.label}`)}>Meine Einstellungen</a></li>
              <li key={`preferences${isLoggedIn}`} className={`${isLoggedIn ? ('block'):('hidden')}`}><a href='../update-email-preferences' className='block py-1 px-6 whitespace-no-wrap text-sm hover:bg-gray-200' onClick={() => this.toggleSubNav(`${nav.primary.label}`)}><span className='inline-flex items-center px-2 py-px rounded text-xs font-medium bg-red-100 text-red-800 mr-1'>neu</span>Ihre email einstellungen</a></li>
              <li key={`bids${isLoggedIn}`} className={`${isLoggedIn ? ('block'):('hidden')}`}><a href='../#!/myBids' className='block py-1 px-6 whitespace-no-wrap text-sm hover:bg-gray-200' onClick={() => this.toggleSubNav(`${nav.primary.label}`)}>Meine Gebote</a></li>
              <li key={`invoice${isLoggedIn}`} className={`${isLoggedIn ? ('block'):('hidden')}`}><a href='../#!/invoices' className='block py-1 px-6 whitespace-no-wrap text-sm hover:bg-gray-200' onClick={() => this.toggleSubNav(`${nav.primary.label}`)}>Meine Rechnungen</a></li>
              <li key={`auctionsArchived${isLoggedIn}`}><a className='block py-1 px-6 whitespace-no-wrap text-sm hover:bg-gray-200' href='../#!/auctionsArchived' onClick={() => this.toggleSubNav(`${nav.primary.label}`)}>Archivierte Auktionen</a></li>
              <li key={`search${isLoggedIn}`}><a className='block py-1 px-6 whitespace-no-wrap text-sm hover:bg-gray-200' href='../#!/search' onClick={() => this.toggleSubNav(`${nav.primary.label}`)}>Auktionen durchsuchen</a></li>
              <li key={`admin${isLoggedIn}`} className={`${isLoggedIn && admin ? ('block'):('hidden')}`}><a href='https://lewes.eu-west-2.bidjs.com/auction-mgt' className='block py-1 px-6 whitespace-no-wrap text-sm hover:bg-gray-200 clickable' target='_blank' rel='noopener noreferrer' onClick={() => this.toggleSubNav(`${nav.primary.label}`)}>Administrator</a></li>
              <li key={`sale${isLoggedIn}`} className={`${isLoggedIn && vendor ? ('block'):('hidden')}`}><a href='../#!/mySales' className='block py-1 px-6 whitespace-no-wrap text-sm hover:bg-gray-200 clickable' onClick={() => this.toggleSubNav(`${nav.primary.label}`)}>Meine Verkäufe</a></li>
              <li key={`logout${isLoggedIn}`} className={`${isLoggedIn ? ('block'):('hidden')}`}><a href='../#!/logout?redirect=%2F' className='block py-1 px-6 whitespace-no-wrap text-sm hover:bg-gray-200' onClick={() => this.toggleSubNav(`${nav.primary.label}`)}>Ausloggen</a></li>
              <li key={`seperator${isLoggedIn}`} className='my-2 border-t' role='separator'></li>
            </>
          )}
          {nav.fields.map((subNav, index) => {
            var subLink = get(subNav.sub_nav_link, 'url',(linkResolver(get(subNav.sub_nav_link, '_meta',[]),[])))
            return (
              <li className={subNav.sub_nav_link_label.toLowerCase()} key={`subNav-${index}`}>
                <a className='block py-1 px-6 whitespace-no-wrap text-sm hover:bg-gray-200' href={subLink} onClick={() => this.toggleSubNav(`${nav.primary.label}`)}>
                  {nav.primary.label.toLowerCase() === 'land' ? (
                    <Flag language={subNav.sub_nav_link_label} />
                  ):(
                    <>{subNav.sub_nav_link_label}</>
                  )}
                </a>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

class Navigation extends PureComponent {

  render() {
    return (
      <StaticQuery
        query={`${headerQuery}`}
        render={withPreview(data => {
          const headerData = data.prismic.allNavigations.edges[0].node
          return (
            <>
              <ul className='flex flex-end'>
                {headerData.nav.map((nav, index) => {
                  var link = get(nav.primary.link, 'url',(linkResolver(get(nav.primary.link, '_meta',[]),[])))
                  return (
                    <React.Fragment key={`nav-${index}`}>
                      {nav.fields.length === 0 ? (
                        <li key={`nav-${index}`} className='flex flex-col justify-center mx-1'>
                          <a className='hidden lg:block p-3 text-gray-600 hover:text-gray-900 focus:text-gray-900' href={link} onClick={ this.toggleNav }>{nav.primary.label}</a>
                        </li>
                      ):(
                        <li className={`flex flex-col justify-center mx-1 relative ${nav.primary.label.toLowerCase()}`}>
                          <div>
                            <Dropdown nav={nav} data={data} isLoggedIn={this.props.loggedIn} />
                          </div>
                        </li>
                      )}
                    </React.Fragment>
                  )
                })}
              </ul>
            </>
          )
        }, headerQuery)}
      />
    )
  }
}

Navigation.defaultProps = {
  loggedIn: false
}

export default Header
