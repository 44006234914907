import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import Footer from './footer'
import '../styles/default.scss'


const layoutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const Layout = props => {
  return (
    <StaticQuery
      query={`${layoutQuery}`}
      render={data => (
        <div className='subpixel-antialiased bg-brand-light-grey text-gray-800 font-sans flex flex-col min-h-screen'>
          <Header siteTitle={data.site.siteMetadata.title} pageName={props.pageName} loggedIn={props.loggedIn} bidJSPath={props.bidJSPath}/>
          <main role='main' id='main'>{props.children}</main>
          <Footer />
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string,
  loggedIn: PropTypes.bool,
  bidJSPath: PropTypes.string
}

export default Layout
